import md5 from 'md5'

//
// Widget URL creator based on amount, date, time and meal ID
//
export function useWidgetUrl(hash, mealId, amount, date, time = undefined) {
  const baseUrl = `https://app.waiteraid.com/reservation/?`
  
  const query = {
    amount: amount,
    app_type: 'bokabord',
    date: date,
    from_url: 'bokabord_fat',
    hash: hash,
    is_bokabord_web: 'Y',
    mealid: mealId,
    time: time,
    hd_meal: mealId ? md5(mealId) : undefined
  }

  return `${baseUrl}${useObjectToQueryString(query)}`
}

//
// Open widget i iFrame overlay by pushing the query
//
export function useWidgetOpen(hash, mealId, amount, date, time = undefined) {
  const route = useRoute()
  const router = useRouter()
  const widgetParams = [hash, mealId, amount, date, time].filter(i => i).join(';')
  const ui = useUiStore()
  ui.widget = true
  router.push({ query: { ...route.query, 'widget': widgetParams }})
}

//
// Close widget by resetting the query param
//
export function useWidgetClose() {
  const route = useRoute()
  const router = useRouter()
  const ui = useUiStore()
  ui.widget = false
  router.push({ query: { ...route.query, widget: undefined }})
}
