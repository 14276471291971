export default {
  en: [],
  sv: [
    {
      code: 301,
      type: "page",
      newPath: "/kontakt",
      oldPaths: [
        "/kontakta-oss"
      ]
    }
  ]
}