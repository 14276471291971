
//
// Grep env attributes from public config 
//
export function useEnv(attribute = null) {
  const runtimeConfigPublic = useRuntimeConfig().public

  if (attribute) return runtimeConfigPublic[attribute]
  
  return {
    ...runtimeConfigPublic
  }
}

//
// Create promise delay
//
export function useDelay(delay) {
  return new Promise(resolve => setTimeout(resolve, delay))
}

//
// Get the URI for a path
//
export function useFullPath(path = '') {
  const url = useRequestURL()
  return `${url.protocol}//${url.host}${path}`
}

//
// Capitalize the first letter in a string 
// 
export function useUcFirst(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

//
// Convert an object into a URL query string.
//
export function useObjectToQueryString(obj) {
  const keyValuePairs = []
  
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      const value = obj[key]
      keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }
  
  return keyValuePairs.join('&')
}

//
// Asset resolver for static images, https://stackoverflow.com/a/76367548
//
export function getImage(fileName) {
  try {
    const modules = import.meta.glob('@/assets/images/**/*.{jpg,png,svg}', { eager: true, import: 'default' })
    const moduleKeys = Object.keys(modules)
    const fileSrc = moduleKeys.find(key => key.endsWith(fileName))
    return fileSrc ? modules[fileSrc] : ''
  } catch (err) {
    console.log(err)
  }
}

//
// Image helper for cloudinary
//
export function useCloudinaryImage(picture, width, height, crop = 'fill', source = 'upload') {
  const imageUrl = 'https://res.cloudinary.com/foodfriends/image'
  const quality = 'q_60'
  const path = source === 'upload' ? `restaurant/${picture}` : picture
  const sizes = width && height ? `w_${width},h_${height}` : ''
  return `${imageUrl}/${source}/${sizes},c_${crop},${quality}/${path}`
}

//
// Use pretty date range based on start and end date
//
export function usePrettyDateRange(startDate, endDate, format = 'dddd D MMMM') {
  const dayjs = useDayjs()

  if (dayjs(startDate).isSame(endDate, 'day')) {
    return useUcFirst(dayjs(startDate).format(format))
  }

  if (dayjs(startDate).isSame(endDate, 'month')) {
    return dayjs(startDate).format('D') + ' - ' + dayjs(endDate).format('D MMMM')
  }

  return dayjs(startDate).format('D MMMM') + ' - ' + dayjs(endDate).format('D MMMM')
}

//
// Use pretty time range based on start and end time
//
export function usePrettyTimeRange(startTime, endTime) {
  if (startTime !== endTime) {
    return `${startTime}-${endTime}`
  }
  
  return startTime
}

//
// Use pretty amount range
//
export function usePrettyAmountRange(minAmount, maxAmount) {
  const { t } = useI18n()

  if (minAmount !== maxAmount) {
    return t('shared.amount', { amount: `${minAmount}-${maxAmount}` })
  }
  
  return t('shared.amount', { amount: minAmount })
}

//
// Show date according to weekday and date
//
export function useCalendarDate(date) {
  const { t } = useI18n()
  const dayjs = useDayjs()

  const isToday = dayjs(date).isToday() 
  const isTomorrow = dayjs(date).add(1, 'day').isTomorrow()
  const isSameWeek = dayjs(date).isSame(dayjs(), 'week')
  
  if (isToday) {
    return useUcFirst(t('shared.today'))
  } else if (isTomorrow) {
    return useUcFirst(t('shared.tomorrow'))
  } else if (isSameWeek) {
    return useUcFirst(dayjs(date).locale(useEnv('locale')).format('dddd'))
  }

  return useUcFirst(dayjs(date).locale(useEnv('locale')).format('dddd - D MMM'))
}

//
// Filter an array of meals to prevent max restaurant occurrences
//
export function useFilterMealsByMaxRestaurantOccurrences(meals, maxOccurrences) {
  // Create empty array
  const restaurantMeals = new Map()
  
  // Group meals by restaurant ID and keep only the first three meals for each restaurant
  meals.forEach(event => {
    const id = event.restaurant.id
    if (!restaurantMeals.has(id)) {
      restaurantMeals.set(id, [event])
    } else {
      const meals = restaurantMeals.get(id)
      if (meals.length < maxOccurrences) {
        meals.push(event)
      }
      restaurantMeals.set(id, meals)
    }
  })

  // Flatten the grouped events into a single array
  return Array.from(restaurantMeals.values()).flat()
}

//
// Group an array of meals on start date and sort by start date chronologically
//
export function useGroupMealsByStartDate(meals) {
  // Step 1: Group the meals by startDate
  const groupedMeals = meals.reduce((acc, meal) => {
    const startDate = meal.startDate
    
    if (!acc[startDate]) {
      acc[startDate] = []
    }
    
    acc[startDate].push(meal)
    return acc
  }, {})
  
  // Step 2: Extract and sort the keys (start dates) chronologically
  const sortedKeys = Object.keys(groupedMeals).sort((a, b) => new Date(a) - new Date(b))
  
  // Step 3: Create a new object with the sorted keys
  return sortedKeys.reduce((acc, key) => {
    acc[key] = groupedMeals[key]
    return acc
  }, {})
}

//
// Group an array of meals based on next dates
//
export function useGroupMealsByNextDates(meals) {
  // Step 1: Group the meals by next date
  const groupedMeals = meals.reduce((acc, meal) => {

    meal.nextDates.forEach(nextDate => {
      if (!acc[nextDate]) {
        acc[nextDate] = []
      }

      acc[nextDate].push(meal)
    })

    return acc
  }, {})
  
  // Step 2: Extract and sort the keys (start dates) chronologically
  const sortedKeys = Object.keys(groupedMeals).sort((a, b) => new Date(a) - new Date(b))
  
  // Step 3: Create a new object with the sorted keys
  return sortedKeys.reduce((acc, key) => {
    acc[key] = groupedMeals[key]
    return acc
  }, {})
}

//
// Group and filter "events", 
// Remove short names/descriptions
// Set max occurrences and group by start date
//
export function useGroupedMeals(meals, maxOccurrences) {
  let filteredMeals = meals
    ?.filter(meal => meal.name.length > 2 && meal.description.length > 2)

  filteredMeals = useFilterMealsByMaxRestaurantOccurrences(filteredMeals, maxOccurrences)

  return useGroupMealsByNextDates(filteredMeals)
}

//
// Get available weekdays based on disabled days
//
export function useAvailableWeekdays(disabledDays) {
  const { t } = useI18n()

  // Get available days by filtering out disabled days
  const availableDays = [1, 2, 3, 4, 5, 6, 7].filter(day => !disabledDays?.includes(day))

  // If no disabledDays are set
  if (!disabledDays) {
    return 
  }

  // If no days are disabled, return 'all days'
  if (disabledDays.length === 0) {
    return t('shared.allDays')
  }

  // If only one day is available, return that day
  if (disabledDays.length === 6) {
    return t(`shared.day.${availableDays[0]}`)
  }

  // If one day is disabled (not monday and sunday)
  if (disabledDays.length === 1 && ![1, 2].includes(disabledDays[0])) {
    const disabledWeekday = t(`shared.day.${disabledDays[0]}`).toLowerCase()
    t('shared.allDaysExcerpt', { day: disabledWeekday })
  }

  // Handle multiple specific cases for disabled days using a lookup object
  const ranges = {
    '7': '1-6',          // Monday-Saturday
    '1': '2-7',          // Tuesday-Sunday
    '6,7': '1-5',        // Monday-Friday
    '1,7': '2-6',        // Tuesday-Saturday
    '1,2,3,4,5': '6-7',  // Saturday-Sunday
    '1,2,3,4': '5-7',    // Friday-Sunday
    '5,6,7': '1-4',      // Monday-Thursday
    '3,4,5,6,7': '1-2',  // Monday-Tuesday
    '1,2,3,7': '2-6',    // Thursday-Saturday
    '1,2,7': '3-6',      // Wednesday-Saturday
  }

  // Sort the disabled days to match the key format in the ranges object
  const key = disabledDays.sort((a, b) => a - b)?.join(',')
  
  // Return the corresponding range if it exists
  if (ranges[key]) {
    return ranges[key]?.split('-')?.map(d => t(`shared.day.${d}`))?.join('-')
  }

  // Return all available days as fallback
  return availableDays?.map(day => t(`shared.day.${day}`))?.join(', ')
}
