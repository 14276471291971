<template>
  <div class="bg-mist grid gap-2 p-4">
    <div class="grid gap-1">
      <p class="text-lg font-bold leading-tight " v-html="meal.name" />
      <div class="my-1 flex gap-4 text-sm opacity-60">
        <div class="flex items-center gap-2">
          <AtomIcon name="calendar" class="w-4" />
          <div v-html="$dayjs(fat.date).format('dddd D MMMM')" />
        </div>
        <div class="flex items-center gap-2">
          <AtomIcon name="users" class="w-4" />
          <div v-html="$t('shared.amount', { amount: fat.amount })" />
        </div>
      </div>
      <!-- <div class="my-1 grid gap-2 text-sm opacity-60" v-if="meal.minAmount || meal.maxAmount || meal.startDate || meal.endDate">
        <div class="flex items-center gap-2" v-if="$dayjs(meal.endDate).diff(meal.startDate, 'day') < 30">
          <AtomIcon name="calendar" class="w-4" />
          <div v-html="[meal.startDate, meal.endDate].join('-')" v-if="meal.startDate !== meal.endDate" />
          <div v-html="$dayjs(meal.startDate).format('dddd D MMMM')" />
        </div>
        <div class="flex items-center gap-2" v-if="meal.minAmount || meal.maxAmount">
          <AtomIcon name="users" class="w-4" />
          <div v-html="$t('restaurant.meals.amount.minMax', { value: `${meal.minAmount}-${meal.maxAmount}` })" v-if="meal.minAmount && meal.maxAmount" />
          <div v-html="$t('restaurant.meals.amount.min', { value: meal.minAmount })" v-else-if="meal.minAmount" />
          <div v-html="$t('restaurant.meals.amount.max', { value: meal.maxAmount })" v-else-if="meal.maxAmount" />
        </div>
      </div> -->
      <RestaurantMealDescription :description="meal.description" class="text-sm" />
    </div>
    <div class="flex gap-2 flex-wrap" v-auto-animate v-if="timeslots.length">
      <div v-for="timeslot in timeslots" :key="'timeslot'+meal.id+timeslot?.timestamp" class="text-sm">
        <button class="w-[3.75em] py-0.5 rounded-sm" @click="useWidgetOpen(restaurant.hash, meal.id, amount, date, timeslot?.time)" :class="{ 'bg-coral text-white': timeslot?.available, 'bg-sand/50 text-black/50': timeslot?.waitlist }">
          {{ timeslot?.time }}
        </button>
      </div>
    </div>
    <p class="text-sm opacity-60" v-if="!timeslots.length" v-html="$t('restaurant.meals.noTimeslots', { date: date })" />
    <!-- <button class="button" data-style="coral" @click="useWidgetOpen(restaurant.hash, meal.id, amount, date)" >
      Boka Bord
    </button> -->
  </div>
</template>

<script setup>
const fat = useFatStore()

const props = defineProps({
  amount: Number,
  date: String,
  restaurant: {
    type: Object,
    default: () => {}
  },
  meal: {
    type: Object,
    default: () => {}
  },
  timeslots: {
    type: Array,
    default: () => []
  }
})
</script>