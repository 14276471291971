import { default as _91_46_46_46post_93jeKTwBEFtUMeta } from "/vercel/path0/pages/[...post].vue?macro=true";
import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as skaldjursveckorna1TaDlpP7dCMeta } from "/vercel/path0/pages/campaigns/skaldjursveckorna.vue?macro=true";
import { default as stockholm_45food_45weeksprv7OalTScMeta } from "/vercel/path0/pages/campaigns/stockholm-food-weeks.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index7pp5WpoXdvMeta } from "/vercel/path0/pages/offers/index.vue?macro=true";
import { default as redirectsyAwQgQokbkMeta } from "/vercel/path0/pages/redirects.vue?macro=true";
import { default as indexkOmdG3yThEMeta } from "/vercel/path0/pages/restaurants/index.vue?macro=true";
export default [
  {
    name: "post",
    path: "/:post(.*)*",
    component: () => import("/vercel/path0/pages/[...post].vue").then(m => m.default || m)
  },
  {
    name: "about",
    path: "/om-oss",
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "skaldjursveckorna",
    path: "/skaldjursveckorna",
    meta: skaldjursveckorna1TaDlpP7dCMeta || {},
    component: () => import("/vercel/path0/pages/campaigns/skaldjursveckorna.vue").then(m => m.default || m)
  },
  {
    name: "stockholm-food-weeks",
    path: "/stockholm-food-weeks",
    meta: stockholm_45food_45weeksprv7OalTScMeta || {},
    component: () => import("/vercel/path0/pages/campaigns/stockholm-food-weeks.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/evenemang",
    component: () => import("/vercel/path0/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "start",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "offers",
    path: "/erbjudanden",
    component: () => import("/vercel/path0/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "redirects",
    path: "/redirects",
    alias: ["/kontakta-oss"],
    component: () => import("/vercel/path0/pages/redirects.vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restauranger",
    component: () => import("/vercel/path0/pages/restaurants/index.vue").then(m => m.default || m)
  }
]