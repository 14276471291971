export default defineNuxtRouteMiddleware((to, from) => {
  const fat = useFatStore()

  //
  // Check if site is in maintenance mode
  //
  if (useEnv('appMode') === 'maintenance') {
    setPageLayout('maintenance')
  }

  //
  // Update FAT on initial load based on query params
  //
  if (to.query && !import.meta.client) {
    fat.setQuery(to.query)
    // console.log('FAT set query, serverside', !import.meta.client)
  }
})
