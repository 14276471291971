
<template>
  <WidgetModal>
    <iframe :src="widgetUrl" frameborder="0" height="100%" scrolling="auto" title="Boka bord" width="100%" :key="widgetUrl" />
  </WidgetModal>
</template>

<script setup>
const route = useRoute()

// Create widget URL based on widget query parameter
const widgetUrl = computed(() => {
  const hash = route.query?.widget?.split(';')[0] ?? undefined
  const mealid = route.query?.widget?.split(';')[1] ?? undefined
  const amount = route.query?.widget?.split(';')[2] ?? undefined
  const date = route.query?.widget?.split(';')[3] ?? undefined
  const time = route.query?.widget?.split(';')[4] ?? undefined
  
  return useWidgetUrl(hash, mealid, amount, date, time)
})


onBeforeUnmount(() => {
  console.log('Close Widget')
})

// Close widget with ESC button
const handleKeydown = (event) => {
  if (route.query?.widget && event.keyCode === 27) {
    useWidgetClose()
  }
}

// Close widget from message in Widget
const handleMessage = (event) => {
  // console.log('handleMessage', event?.data)

  if (event.data === 'closeWaiteraidFrame') {
    useWidgetClose()
  }

  if (event.data === 'reservationSuccess') {
    // console.log('\n\nreservationSuccess!')

    // setTimeout(() => {
    //   // console.log('Push Route')
    //   useWidgetClose()
    // }, 3000)
  }
}

onMounted( () => {
  window.addEventListener('keydown', handleKeydown)
  window.addEventListener('message', handleMessage, false);

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeydown)
    window.removeEventListener('message', handleMessage);
  })
})
</script>

<style lang="scss" scoped>
//
</style>