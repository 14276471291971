import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const nodeEnv = runtimeConfig.public.nodeEnv
  const gtmCode = runtimeConfig.public.gtmCode ? runtimeConfig.public.gtmCode : 'GTM-XXXXXXX'

  // // if (nodeEnv !== 'production') {
  // console.log('--------')
  // console.log('')
  // console.log('plugin:          vue-gtm')
  // console.log('nodeEnv:        ', nodeEnv)
  // console.log('gtmCode:        ', gtmCode)
  // console.log('enabled:        ', !!gtmCode && nodeEnv === 'production')
  // console.log('debug:          ', !!gtmCode && nodeEnv !== 'production')
  // console.log('runtimeConfig   ', runtimeConfig.public)
  // console.log('')
  // console.log('--------')
  // // }
  
  nuxtApp.vueApp.use(
    createGtm({
      id: gtmCode,
      defer: false, // Can be set to defer to speed up page load
      compatibility: false, // Adds async and defer to script tag
      // nonce: 'xxxxxxxx', // Will add nonce to the script tag
      enabled: !!gtmCode && nodeEnv === 'production', // Disable plugin, defaults to true
      debug: false, // !!gtmCode && nodeEnv !== 'production', // Display console logs (optional)
      loadScript: true,
      vueRouter: useRouter(), // Hook in the Vue Router
      trackOnNextTick: false // Whether or not to call trackView in Vue.nextTick
    })
  )
})