<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
    <FatSearch />
    <Preview />
    <Widget />
    <Debug v-if="route.query.compare" />
    <ClientOnly>
      <div class="text-xs opacity-10 mt-12 text-center">
        Built: {{ useAppConfig().buildDate }}
      </div>
    </ClientOnly>
  </NuxtLayout>
</template>

<script setup>
const route = useRoute()
const app = useAppStore()
const fat = useFatStore()

// Fetch cities from API
const { data: cities } = await useFetch('/cities', {
  key: 'cities',
  baseURL: useEnv('apiBase'),
  query: {
    limit: 500
  }
})

// Set FAT default values
fat.setDefaults()

// Add page view, update place and set counter for seconds
onMounted(() => {
  app.updateUts()
  app.addPageview()

  // Global interval (not really used yet)
  const interval = setInterval(() => {
    app.updateUts()
  }, 1000)

  onBeforeUnmount(() => {
    clearInterval(interval)
  })
})

// Increment page views and update place
watch(() => route.query, () => {
  app.addPageview()
})
</script>