<template>
  <DialogRoot v-model:open="ui.preview">
    <DialogPortal>
      <DialogContent class="modal-content">
        <DialogTitle as="p"></DialogTitle>
        <DialogDescription></DialogDescription>
        <slot />
      </DialogContent>
    </DialogPortal>
    <DialogOverlay class="modal-overlay" />
  </DialogRoot>
</template>

<script setup lang="ts">
const ui = useUiStore()
</script>

<style lang="scss" scoped>
.modal-content {
  @apply fixed z-50;
  @apply flex flex-col bg-white;
  @apply inset-0 overflow-hidden;

  @screen sm {
    @apply w-4/5 max-w-2xl top-12 left-1/2 bottom-0;
    @apply -translate-x-1/2 h-auto rounded-t-2xl;
  }
}

.modal-overlay {
  @apply fixed z-40 inset-0 bg-black/40 backdrop-blur-md;
}

@keyframes fadeInModal {
  from {
    @apply opacity-0 mt-16;
  }
  to {
    @apply opacity-100;
  }
}

@keyframes fadeOutModal {
  from {
    @apply opacity-100;
  }
  to {
    @apply opacity-0 mt-16;
  }
}

.modal-content[data-state="open"] {
  animation: fadeInModal 300ms ease-out;
}

.modal-content[data-state="closed"] {
  animation: fadeOutModal 200ms ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal-overlay[data-state="open"] {
  animation: fadeIn 300ms ease-out;
}

.modal-overlay[data-state="closed"] {
  animation: fadeOut 200ms ease-in;
}
</style>