<template>
  <DialogRoot v-model:open="ui.fatSearch">
    <DialogPortal>
      <DialogContent class="modal-content">
        <slot />
      </DialogContent>
    </DialogPortal>
    <DialogOverlay class="modal-overlay" />
  </DialogRoot>
</template>

<script setup lang="ts">
const ui = useUiStore()
</script>

<style lang="scss" scoped>
.modal-content {
  @apply fixed z-50;
  @apply flex flex-col bg-white;
  @apply h-[100vh] w-full sm:max-w-md top-0 left-0;
}

.modal-overlay {
  @apply fixed z-40 inset-0 bg-black/50;
}

@keyframes fadeInModal {
  from {
    @apply opacity-0 mt-4;
  }
  to {
    @apply opacity-100;
  }
}

@keyframes fadeOutModal {
  from {
    @apply opacity-100;
  }
  to {
    @apply opacity-0 mt-4;
  }
}

@screen sm {
  @keyframes fadeInModal {
    from {
      @apply opacity-100 mt-0 -ml-8;
    }
  }

  @keyframes fadeOutModal {
    to {
      @apply opacity-0 mt-0 -ml-8;
    }
  }
}

.modal-content[data-state="open"] {
  animation: fadeInModal 300ms ease-out;
}

.modal-content[data-state="closed"] {
  animation: fadeOutModal 200ms ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal-overlay[data-state="open"] {
  animation: fadeIn 300ms ease-out;
}

.modal-overlay[data-state="closed"] {
  animation: fadeOut 200ms ease-in;
}
</style>