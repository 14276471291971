
<template>
  <PreviewModal>
    <div class="flex flex-col h-full" v-if="isLoaded">
      <!-- <div class="debug text-xs">
        <p>restaurantSlug: {{ preview.restaurantSlug }}</p>
        <p>mealId: {{ preview.mealId }}</p>
      </div> -->
      <div class="flex-1 overflow-y-auto">
        <!-- <div class="sticky z-40 top-0 left-0 w-full box">Test</div> -->
        <div class="absolute z-50 top-2 right-2 text-white" @click="preview.close()">
          <button class="flex items-center gap-2 px-2 py-2 bg-black/40 rounded-lg opacity-80 transition-all duration-300 md:hover:opacity-100">
            <span class="text-xs">{{ $t('shared.close') }}</span>
            <AtomIcon name="circle-xmark" />
          </button>
        </div>
        <div class="aspect-video bg-sand relative z-40">
          <AtomImage :src="useCloudinaryImage(restaurant.cover, 900, 600)" />
        </div>
        <div class="grid gap-8 p-4 md:p-6">
          <div class="grid gap-2">
            <div class="grid gap-1">
              <h2 class="text-2xl sm:text-3xl font-bold" v-html="restaurant.name" />
              <div class="flex items-center gap-2">
                <AtomIcon name="location-dot" class="text-coral" />
                <p class="bg-mist px-2 rounded-full text-xs py-0.5 border" v-if="restaurant.distance">{{ restaurant.distance }}km</p>
                <p class="text-sm opacity-60 line-clamp-1" v-html="[restaurant.address, restaurant.city].join(', ')" />
              </div>
            </div>
            <p class="font-bold leading-tight" v-html="restaurant.shortDescription" />
            <p class="text-sm" v-html="restaurant.longDescription" />
          </div>
          <!-- EVENT MODAL -->
          <div v-if="preview.mealId && !!meal.id">
            <RestaurantEvent :restaurant="restaurant" :meal="meal" />
          </div>
          <!-- RESTAURANT MODAL -->
          <div class="grid gap-8" v-if="!preview.mealId || !meal?.id">
            <RestaurantMeals :restaurant="restaurant" />
            <RestaurantOpeningHours :restaurant="restaurant" />
            <RestaurantMap :restaurant="restaurant" />
            <RestaurantContact :restaurant="restaurant" />
            <!-- WIP -->
            <!-- <RestaurantGiftcard :restaurant="restaurant" class="box" /> -->
            <!-- <RestaurantDishes :restaurant="restaurant" class="box" /> -->
            <!-- <RestaurantImages :restaurant="restaurant" class="box" /> -->
            <!-- <RestaurantMenus :restaurant="restaurant" class="box" /> -->
          </div>
        </div>
      </div>
      <!-- <div class="p-4 flex justify-center" @click="preview.close()">
        <button class="button-outline min-w-40" data-style="coral">{{ $t('shared.close') }}</button>
      </div> -->
    </div>
  </PreviewModal>
</template>

<script setup>
const route = useRoute()
const router = useRouter()
const ui = useUiStore()
const preview = usePreviewStore()
const restUrl = computed(() => `/restaurants/${preview.restaurantId}`)
const isLoaded = computed(() => preview.restaurantId === restaurantData.value?.data?.id)

// Fetch restaurant data from API
const { data: restaurantData } = useFetch(restUrl, {
  key: 'preview',
  baseURL: useEnv('apiBase')
})

const restaurant = computed(() => restaurantData?.value?.data)
const meal = computed(() => restaurant?.value?.meals?.find(meal => meal.id === preview.mealId))

// Push query for tracking purpose
watch(() => ui.preview, () => {
  if (ui.preview && preview.restaurantId) {
    router.push({ query: { 
      ...route.query, 
      preview: preview.restaurantId || undefined, 
      mealId: preview.mealId || undefined
    }})
  } else {
    router.push({ query: {
      ...route.query, 
      preview: undefined, 
      mealId: undefined 
    }})
  }
})
</script>

<style lang="scss" scoped>
// 
</style>