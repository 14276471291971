<template>
  <div class="map">
    <div class="map__pin" v-if="pin">
      <AtomIcon class="map__icon" name="location-dot-solid" />
      <div class="map__fill"></div>
    </div>
    <iframe class="map__iframe" :style="{ 'opacity': opacity }"
      width="100%"
      height="100%"
      :src="url"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  lat: Number,
  lon: Number,
  pin: {
    type: Boolean,
    default: true
  },
  zoom: {
    type: Number,
    default: 14
  },
  opacity: {
    type: Number,
    default: 100
  }
})

// Query parameters for map
const locale = useEnv('locale')
const coordinates = `${props.lat},${props.lon}`

// Create Google Maps URL
const url = computed(() => `https://maps.google.com/maps?q=${coordinates}&hl=${locale}&z=${props.zoom}&output=embed`)
</script>

<style lang="scss" scoped>
.map {
  @apply overflow-hidden relative h-full;

  &__pin {
    @apply absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    @apply text-3xl -mt-3;
  }

  &__icon {
    @apply relative z-20;
  }

  &__fill {
    @apply absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-white -mt-1;
  }

  &__iframe {
    @apply grayscale;
    @apply scale-50 pointer-events-none;
    height: calc(100% + 40rem);
    width: calc(100% + 40rem);
    margin: -20rem -20rem;
  }
}
</style>