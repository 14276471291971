import { acceptHMRUpdate, defineStore } from 'pinia'

export const useFatStore = defineStore('fat', {
  persist: true,

  state: () => ({
    loading: false,
    date: null,
    amount: 2,
    cityId: null,
    coordinates: null,
    distance: 3000,
    meal: 'dinner',
    mealStartTime: '16:30',
    mealEndTime: '23:30',
    preferredTime: '19:00',
    excludeEmpty: false,
    excludeWaitlist: false,
    tags: {
      cousineIds: '',
      amenityIds: '',
      tagIds: '',
      dishIds: ''
    }
  }),

  getters: {
    location() {
      return this.coordinates
        ? `${this.coordinates},${this.distance}`
        : null
    },
    filterCount() {
      return [
        ...this.tags.cousineIds?.split(',') || [],
        ...this.tags.amenityIds?.split(';') || [],
        ...this.tags.tagIds?.split(';') || [],
        ...this.tags.dishIds?.split(',') || [],
      ]?.filter(i => i)?.length
    },
    timeRange() {
      return `${this.mealStartTime}-${this.mealEndTime}`
    },
    mealTypes() {
      return [
        {
          slug: 'breakfast',
          text: 'Frukost',
          mealStartTime: '06:00',
          mealEndTime: '11:00',
          preferredTime: '09:00'
        },
        {
          slug: 'lunch',
          text: 'Lunch',
          mealStartTime: '11:00',
          mealEndTime: '15:00',
          preferredTime: '12:00'
        },
        {
          slug: 'dinner',
          text: 'Middag',
          mealStartTime: '16:30',
          mealEndTime: '23:30',
          preferredTime: '19:00'
        }
      ]
    },
    query() {
      const dayjs = useDayjs()

      return {
        scope: 'bokabord.se',
        bookable: true,
        mealTypes: '0,1,2,3,4,5',
        amount: this.amount || 2,
        date: this.date || dayjs().format('YYYY-MM-DD'),
        cityIds: this.cityId || undefined,
        latitude: this.location?.split(',')[0] || undefined,
        longitude: this.location?.split(',')[1] || undefined,
        distance: !!this.location ? parseInt(this.distance) : undefined,
        waitlist: !this.excludeEmpty || undefined,
        excludeEmptyTimeslots: !this.excludeEmpty || undefined,
        timeslotsRange: this.timeRange,
        tagIds: [
          this.tags.amenityIds, 
          this.tags.cousineIds, 
          this.tags.dishIds, 
          this.tags.tagIds
        ].filter(tag => tag).join(';') || undefined,
        includeFilterData: true,
        includeRestaurantData: true
      }
    }
  },

  actions: {
    setDefaults() {
      this.loading = false
      this.excludeEmpty = false
      this.excludeWaitlist = false
    },
    setDate(date) {
      const dayjs = useDayjs()
      const passedOrExpired = (!date || dayjs().isAfter(date, 'day'))

      this.date = passedOrExpired
        ? dayjs().format('YYYY-MM-DD')
        : date
    },
    setAmount(amount) {
      this.amount = parseInt(amount || this.amount)
    },
    setCityId(cityId) {
      this.cityId = parseInt(cityId || this.cityId) || null
    },
    setLocation(location) {
      const locationParts = location?.split(',') || []

      if (locationParts.length) {
        this.coordinates = [locationParts[0], locationParts[1]].join(',')
        this.distance = parseInt(locationParts[2] || 3000)
      }
    },
    setDistance(distance) {
      this.distance = parseInt(distance)
    },
    setTimeRange(timeRange) {
      const timeRangeParts = timeRange?.split('-') || []
      // console.log('timeRange', timeRange, timeRangeParts)

      if (timeRangeParts.length) {
        this.mealStartTime = timeRangeParts[0]
        this.mealEndTime = timeRangeParts[1]
        // this.preferredTime = '19:00', // FIX THIS LATER
      }
    },
    setMealType(mealType) {
      const meal = this.mealTypes.find(meal => meal.slug === mealType)
      if (meal) {
        this.meal = mealType
        this.mealStartTime = meal?.mealStartTime
        this.mealEndTime = meal?.mealEndTime
        this.preferredTime = meal?.preferredTime
      }
    },
    setMode(excludeEmpty) {
      this.excludeEmpty = excludeEmpty
      this.excludeWaitlist = excludeEmpty
    },
    addAmount() {
      this.amount = this.amount < 8
        ? this.amount+1
        : this.amount
      this.pushQuery()
    },
    removeAmount() {
      this.amount = this.amount > 1
        ? this.amount-1
        : this.amount
      this.pushQuery()
    },
    nextDay() {
      const dayjs = useDayjs()
      this.date = dayjs(this.date).add(1, 'days').format('YYYY-MM-DD')
      this.pushQuery()
    },
    prevDay() {
      const dayjs = useDayjs()
      this.date = dayjs(this.date).add(-1, 'days').format('YYYY-MM-DD')
      this.pushQuery()
    },
    nextDay() {
      const dayjs = useDayjs()
      this.date = dayjs(this.date).add(1, 'days').format('YYYY-MM-DD')
      this.pushQuery()
    },
    setQuery(query) {
      this.setDate(query.date)
      this.setAmount(query.amount)
      this.setCityId(query.cityId)
      this.setLocation(query.location)
      this.setMealType(query.meal)
      this.setTimeRange(query.timeRange)
      this.tags = {
        cousineIds: query.cousineIds || '',
        amenityIds: query.amenityIds || '',
        tagIds: query.tagIds || '',
        dishIds: query.dishIds || '',
      }
    },
    pushQuery() {
      const route = useRoute()
      const router = useRouter()
      
      router.push({ query: { 
        ...route.query,
        amount: this.amount,
        date: this.date,
        cityId: this.cityId || undefined,
        location: this.location || undefined,
        timeRange: this.timeRange,
        meal: undefined
      }})
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFatStore, import.meta.hot))
}
