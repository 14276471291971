<template>
  <div class="fixed z-[9999] bottom-0 left-0 w-64">
    <div class="font-mono whitespace-pre text-white/80 bg-black/90 text-xs p-2">
      <p class="font-bold" v-html="`FAT`" />
      <p v-html="`amount: ${fat.amount}`" />
      <p v-html="`date: ${fat.date}`" />
      <p v-html="`meal: ${fat.meal}`" />
      <p v-html="`cityId: ${fat.cityId ?? ''}`" />
      <div class="mt-2 border-t border-t-white/20 pt-2" v-if="fatRestaurants">
        <p class="font-bold" v-html="`FAT Result`" />
        <p v-html="`restaurants: ${fatRestaurants.length}`" />
        <p class="mt-1">
          <a class="btn" :href="`${compBaseUrl}/restauranger?${compQuery}`" target="_blank">Compare FAT</a>
        </p>
      </div>
      <div class="mt-2 border-t border-t-white/20 pt-2" v-if="previewRestaurant && preview?.restaurantId">
        <p class="font-bold" v-html="`Preview`" />
        <p v-html="`slug: ${previewRestaurant.slug}`" />
        <p v-html="`id: ${preview.restaurantId}`" />
        <p v-html="`meal: ${preview.mealId}`" v-if="preview.mealId" />
        <p class="mt-1">
          <a class="btn" :href="`${compBaseUrl}/restaurang/${previewRestaurant.slug}?${compQuery}`" target="_blank">{{ previewRestaurant.name }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const route = useRoute()
const fat = useFatStore()
const preview = usePreviewStore()
const compBaseUrl = 'https://bb-labs-fat.vercel.app'

// Compare URL
const compQuery = computed(() => `cityId=${fat.cityId}&timeRange=${fat.timeRange}&amount=${fat.amount}&date=${fat.date}`)

// Get FAT data
const { data: fatData } = useNuxtData('fat-result')
const fatRestaurants = computed(() => fatData.value?.data?.restaurants)

// Get preview data
const { data: previewData } = useNuxtData('preview')
const previewRestaurant = computed(() => previewData.value?.data)
</script>