<template>
  <FatSearchModal>
    <div class="p-4 sticky top-0 bg-white">
      <div class="flex justify-between">
        <div>
          <DialogTitle as="h2" class="font-bold text-2xl">{{ $t('fat.searchHeading') }}</DialogTitle>
          <DialogDescription class="hidden"></DialogDescription>
        </div>
        <div class="flex items-center gap-2 text-sm cursor-pointer" @click="fatInit">
          <div>{{ $t('shared.close') }}</div>
          <AtomIcon name="circle-xmark" />
        </div>
      </div>
    </div>
    <div class="px-4 pt-2 pb-24 sm:pb-4 overflow-y-auto">
      <div class="flex flex-col gap-6">
        <FatSearchAmount />
        <FatSearchDate />
        <FatSearchMeal />
      </div>
    </div>
    <div class="grid gap-4 p-4 sticky bottom-0 bg-white">
      <button class="button relative text-sm w-full" @click="fatInit" :class="{ 'pointer-events-none': fat.loading }" data-style="coral">
        <AtomSpinner class="w-4 text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0" :class="{ 'opacity-100': fat.loading }" />
        <span :class="{ 'opacity-0': fat.loading }">
          {{ $t('fat.button', { count: route.name === 'restaurants' ? total : '' }) }}
        </span>
      </button>
    </div>
  </FatSearchModal>
</template>

<script async setup>
const route = useRoute()
const router = useRouter()
const ui = useUiStore()
const fat = useFatStore()

// Navigate to search result
const fatInit = () => {
  ui.fatSearch = false
  fat.pushQuery()
  
  // if (route.name !== 'restaurants') {
  //   setTimeout(() => {
  //     router.push({ name: 'restaurants', query: { ...route.query }})
  //   }, 100)
  // }
}

// Use FAT result data
const { data: fatResult } = useNuxtData('fat-result')
const total = computed(() => fatResult.value?.data?.total)
</script>