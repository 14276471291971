import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirects_45global from "/vercel/path0/middleware/redirects.global.ts";
import shared_45global from "/vercel/path0/middleware/shared.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirects_45global,
  shared_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}