<template>
  <div class="calendar">
    <client-only>
      <VDatePicker 
        v-model.string="fat.date" 
        is-required 
        :locale="useEnv('locale')"
        :min-date="$dayjs().format('YYYY-MM-DD')"
        :max-date="$dayjs().add(90, 'days').format('YYYY-MM-DD')"
        :attributes="attrs" 
        :masks="{ modelValue: 'YYYY-MM-DD' }" 
        :onchange="fat.pushQuery()"
      />
    </client-only>
  </div>
</template>

<script setup lang="ts">
const fat = useFatStore()

const attrs = ref([
  {
    key: 'today',
    dot: true,
    dates: new Date()
  }
])
</script>

<style lang="scss" scoped>
.calendar {
  &:deep(.vc-bordered) {
    @apply border-0 w-full; 
  }

  &:deep(.vc-header) {
    @apply text-base p-0 m-0;
  }

  &:deep(.vc-header .vc-arrow) {
    @apply text-coral;
  }

  &:deep(.vc-header .vc-arrow:hover) {
    @apply bg-transparent;
  }
  
  &:deep(.vc-weekday) {
    @apply text-black opacity-60 text-xs font-medium;
  }

  &:deep(.vc-weeks) {
    @apply p-0;
  }

  &:deep(.vc-dot) {
    @apply bg-coral;
  }

  &:deep(.vc-day-content) {
    @apply transition-all;
  }

  &:deep(.vc-day-content:hover:not(.vc-highlight-content-solid)) {
    @apply bg-mist;
  }

  &:deep(.vc-highlight-bg-solid), 
  &:deep(.vc-day-content:hover) {
    @apply bg-coral;
  }

  &:deep(.vc-focus) {
    @apply shadow-none;
  }

  &:deep(.vc-nav-item.is-active) {
    @apply bg-coral;
  }
}
</style>