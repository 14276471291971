import redirects from '@/data/redirects'

export default defineNuxtRouteMiddleware((to, from) => {
  // console.log('Redirects Middleware', to.path.replace(/\/$/, ''))
  const locale = useEnv('locale')

  // Find matching redirect
  const redirect = redirects[locale].find((redirect) =>
    redirect.oldPaths.includes(to.path.replace(/\/$/, ''))
  )

  // Initiate redirect
  if (redirect) {
    console.log(`Redirect to ${redirect.newPath}`)
    return navigateTo({ path: redirect.newPath }, { redirectCode: redirect.code })
  }

  // Remove trailing slash
  else if (to.path.endsWith('/') && to.path !== '/') {
    console.log(`Remove trailing slash from ${to.path}`)
    return navigateTo(to.path.slice(0, -1), { redirectCode: 301 })
  }
})
