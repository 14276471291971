<template>
  <img :class="classes"
    :src="src"
    :alt="alt"
    width="auto"
    height="auto"
    loading="lazy"
  />
</template>

<script setup>
const props = defineProps({
  src: {
    type: String,
    default: null
  },
  alt: {
    type: String,
    default: null
  },
  class: {
    type: String,
    default: ''
  }
})

const classes = computed(() => {
  const defaultClasses = 'block w-full h-full object-cover'
  const providedClasses = props.class.split(' ')
  const hasObjectClass = providedClasses.some(cls => cls.startsWith('object-'))
  
  if (hasObjectClass) {
    return defaultClasses
      .split(' ')
      .filter(cls => !cls.startsWith('object-'))
      .concat(providedClasses)
      .join(' ')
  }
  
  return defaultClasses + ' ' + props.class
})
</script>