<template>
  <div class="date" v-auto-animate>
    <div class="date__header">
      <div class="grid gap-0.5">
        <div class="text-xl font-bold">{{ $t('fat.date.heading') }}</div>
        <div class="text-sm">{{ $t('fat.date.leading') }}</div>
      </div>
      <div class="flex items-center gap-2 text-sm cursor-pointer" @click="ui.fatCalendar = !ui.fatCalendar">
        <AtomIcon name="calendar" />
        <div>{{ $t('fat.date.link') }}</div>
      </div>
    </div>
    <div class="date__options" v-if="!ui.fatCalendar">
      <div v-for="option in options" :key="option.date" class="date__option">
        <div class="date__label" @click="onChange(option.date)">
          {{ option.text }}
        </div>
        <div class="date__pill" :class="{ 'selected': fat.date === option.date }" @click="onChange(option.date)">
          {{ dayjs(option.date).format('D') }}
        </div>
      </div>
    </div>
    <FatSearchCalendar v-else />
  </div>
</template>

<script setup>
const { t } = useI18n()
const dayjs = useDayjs()
const ui = useUiStore()
const fat = useFatStore()

// Update date
const onChange = (value) => {
  fat.setDate(value)
  fat.pushQuery()
}

// Default dates
const options = Array.from({ length: 7 }, (_, index) => {
  const date = dayjs().add(index, 'day').format('YYYY-MM-DD')
  let text = dayjs(date).format('ddd')

  if (dayjs(date).isToday()) text = t('fat.date.today')
  if (dayjs(date).isTomorrow()) text = t('fat.date.tomorrow')
  
  return {
    date: date,
    text: text,
  }
})

// // Calendar Config
// const calendarAttrs = ref([
//   {
//     key: 'today',
//     dates: dayjs().format('YYYY-MM-DD'),
//     dot: true,
//   },
// ])
</script>

<style lang="scss" scoped>
.date {
  &__header {
    @apply flex justify-between items-end mb-4;
  }

  &__options {
    @apply flex items-center justify-between;
  }

  &__option {
    @apply flex flex-col items-center justify-center;
  }

  &__label {
    width: calc(100% + 1.5rem);
    @apply -mx-3 text-xs opacity-60 text-center mb-1;
  }
  
  &__pill {
    @apply font-bold cursor-pointer;
    @apply w-8 h-8 rounded-full;
    @apply flex items-center justify-center transition-all;

    &.selected {
      @apply bg-coral text-white;
      @apply pointer-events-none cursor-default;
    }

    @screen md {
      &:hover {
        @apply bg-mist;
      }
    }
  }
}
</style>