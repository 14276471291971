<template>
  <div class="meal" :key="restaurant.id">
    <!-- <div class="flex flex-col-reverse gap-0.5">
      <h2 class="text-sm" v-html="$t('restaurant.meals.leading', restaurant)" />
      <p class="text-xl font-bold tracking-tight" v-html="$t('restaurant.meals.heading')" />
    </div> -->
    <!-- <div class="flex items-center justify-between" :class="{ 'pointer-events-none': pending }">
      <div class="amount">
        <div v-for="amount in 8" :key="`amount-${amount}`" class="amount__option">
          <div class="amount__pill" :class="{ 'selected': fat.amount === amount }" @click="onChangeAmount(amount)">
            {{ amount }}
          </div>
        </div>
      </div>
      <div class="date">
        <button class="date__button" @click="fat.prevDay()" :disabled="$dayjs(fat.date).isToday()">
          <AtomIcon name="circle-arrow-left" />
        </button>
        <div class="date__center">
          <AtomSpinner class="w-4 text-coral absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0" :class="{ 'opacity-100': pending }" />
          <div class="font-medium text-sm transition-all" v-html="$dayjs(fat.date).format('D MMM')" :class="{ 'opacity-0': pending }" />
        </div>
        <button class="date__button" @click="fat.nextDay()" :disabled="$dayjs(fat.date).isSame($dayjs().add(90, 'days'), 'day')">
          <AtomIcon name="circle-arrow-right" />
        </button>
      </div>
    </div> -->
    <div class="rounded-sm grid gap-0.5">
      <RestaurantMeal v-for="meal in meals" 
        :key="meal.id"
        :amount="fat.amount"
        :date="fat.date"
        :restaurant="restaurant" 
        :meal="meal" 
        :timeslots="timeslots[meal.id]" />
    </div>
  </div>
</template>
<script setup>
const fat = useFatStore()

const props = defineProps({
  restaurant: {
    type: Object,
    default: () => {}
  }
})

const { data: fatResult } = await useFetch('/search/fat', {
  key: 'restaurant-fat',
  baseURL: useEnv('apiBase'),
  query: computed(() => ({
    scope: 'bokabord.se',
    bookable: true,
    mealTypes: '0,1,2,3,4,5',
    amount: fat.amount,
    date: fat.date || dayjs().format('YYYY-MM-DD'),
    latitude: fat.coordinates?.split(',')[0] || undefined,
    longitude: fat.coordinates?.split(',')[1] || undefined,
    distance: !!fat.coordinates ? parseInt(fat.distance) : undefined,
    waitlist: !fat.excludeEmpty || undefined,
    excludeEmptyTimeslots: !fat.excludeEmpty || undefined,
    timeslotsRange: `${fat.timeRange}`,
    restaurantIds: props.restaurant.id,
  }))
})

// Get all meals based on FAT result
const meals = computed(() => fatResult.value?.data?.restaurants[0]?.meals)

// Create timeslots dictionary
const timeslots = computed(() =>
  fatResult.value?.data?.restaurants[0]?.meals
    ?.reduce((meals, meal) => {
      meals[meal.id] = meal?.timeslots
      return meals
    }, {}) ?? {}
)

// // Update amount
// const onChangeAmount = (value) => {
//   fat.setAmount(value)
//   fat.pushQuery()
// }
</script>

<style lang="scss" scoped>
.meal {
  @apply grid gap-4 relative;
}

.date {
  @apply flex items-center justify-between -mx-1;

  &__center {
    @apply w-16 text-center relative;
  }

  &__button {
    @apply transition-all text-xl p-1 -my-1;

    &:disabled {
      @apply opacity-20;
    }
  }
}

.amount {
  @apply flex items-center justify-between;
  
  &__option {
    @apply flex justify-center;
  }
  
  &__pill {
    @apply font-bold cursor-pointer;
    @apply w-7 h-7 sm:w-8 sm:h-8 rounded-full;
    @apply flex items-center justify-center transition-all;

    &.selected {
      @apply bg-coral text-white;
      @apply pointer-events-none cursor-default;
    }

    @screen md {
      &:hover {
        @apply bg-mist;
      }
    }
  }
}
</style>