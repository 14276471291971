<template>
  <div class="max-w-lg mx-auto py-6">
    <div class="grid gap-8">
      <!-- Intro -->
      <div class="max-w-xs mx-auto text-center grid gap-4">
        <AtomIcon name="bb-logo" class="text-[3rem] text-coral" />
        <div class="grid gap-2">
          <h1 class="text-2xl font-bold" v-html="$t('shared.error.404.h1')" />
          <p class="text-sm" v-html="$t('shared.error.404.leading')" />
          <button class="button text-sm flex justify-self-center mt-4" data-style="coral" @click="handleError">
            {{ $t('shared.error.404.button') }}
          </button>
        </div>
      </div>
    </div>
    <!--
    <h2>{{ error.statusCode }}</h2>
    <button class="btn" @click="handleError">Clear errors</button>
    <br>
    <div class="debug">{{error}}</div>
    -->
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  error: Object
})

const handleError = () => clearError({ redirect: '/' })
</script>
