<template>
  <div class="contact">
    <div class="flex flex-col-reverse gap-0.5">
      <h2 class="text-sm" v-html="$t('restaurant.contact.leading', restaurant)" />
      <p class="text-xl font-bold tracking-tight" v-html="$t('restaurant.contact.heading')" />
    </div>
    <div class="contact__list">
      <!-- Address -->
      <a :href="locationUrl" class="contact__item" target="_blank">
        <AtomIcon name="location-dot" class="text-lg w-6 text-coral" />
        <!-- <span class="text-sm">{{ [restaurant.address, restaurant.city].join(', ') }}</span> -->
        <span class="grid gap-1">
          <span class="text-sm font-medium">{{ restaurant.name }}</span>
          <span class="text-sm">{{ restaurant.address }}</span>
          <span class="text-sm">{{ restaurant.city }}</span>
        </span>
      </a>
      <!-- Email, Phone & Website -->
      <div class="grid gap-2">
        <a :href="emailUrl" class="contact__item" target="_blank" v-if="emailUrl">
          <AtomIcon name="envelope" class="text-lg w-6 text-coral" />
          <span class="text-sm">{{ restaurant.email }}</span>
        </a>
        <a :href="phoneUrl" class="contact__item" target="_blank" v-if="phoneUrl">
          <AtomIcon name="mobile" class="text-lg w-6 text-coral" />
          <span class="text-sm">{{ restaurant.phone }}</span>
        </a>
        <a :href="siteUrl" class="contact__item" target="_blank" v-if="siteUrl">
          <AtomIcon name="browser" class="text-lg w-6 text-coral" />
          <span class="text-sm">{{ restaurant.website }}</span>
        </a>
      </div>
      <!-- Instagram, Facebook & Spotify -->
      <div class="grid gap-2">
        <a :href="instagramUrl" class="contact__item" target="_blank" v-if="instagramUrl">
          <AtomIcon name="instagram" class="text-lg w-6 text-coral" />
          <span class="text-sm">Instagram</span>
        </a>
        <a :href="facebookUrl" class="contact__item" target="_blank" v-if="facebookUrl">
          <AtomIcon name="facebook" class="text-lg w-6 text-coral" />
          <span class="text-sm">Facebook</span>
        </a>
        <a :href="spotifyUrl" class="contact__item" target="_blank" v-if="spotifyUrl">
          <AtomIcon name="spotify" class="text-lg w-6 text-coral" />
          <span class="text-sm">Spotify</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  restaurant: {
    type: Object,
    default: () => {}
  }
})

// Google Maps URL
const coordinates = `${props.restaurant?.location?.lat},${props.restaurant?.location?.lon}`
const locationUrl = computed(() => `https://www.google.com/maps/place/${coordinates}`)

// Email URL
const emailUrl = computed(() => props.restaurant?.email?.trim()
  ? `mailto:${props.restaurant?.email?.trim()}`
  : null)

// Phone URL
const phoneUrl = computed(() => props.restaurant?.phone?.trim()
  ? `tel:${props.restaurant?.phone.replace(/ /g, '')?.trim()}`
  : null)

// Site URL
const siteUrl = computed(() => props.restaurant?.website?.trim()
  ? props.restaurant?.website?.trim()
  : null)

// Instagram URL
const instagramUrl = computed(() => props.restaurant?.socialMedia?.instagram?.trim()
  ? `${props.restaurant?.socialMedia?.instagram?.trim()}`
  : null)

// Facebook URL
const facebookUrl = computed(() => props.restaurant?.socialMedia?.facebook?.trim()
  ? `${props.restaurant?.socialMedia?.facebook?.trim()}`
  : null)

// Spotify URL
const spotifyUrl = computed(() => props.restaurant?.socialMedia?.spotify?.trim()
  ? `https://open.spotify.com/playlist/${props.restaurant?.socialMedia?.spotify.trim()}`
  : null)
</script>

<style lang="scss" scoped>
.contact {
  @apply grid gap-4;

  &__list {
    @apply grid gap-4;
  }

  &__item {
    @apply flex items-start gap-2 relative;

    &.disabled {
      @apply pointer-events-none opacity-20 grayscale;
    }
  }
}
</style>