import { acceptHMRUpdate, defineStore } from 'pinia'

export const useUiStore = defineStore('ui', {
  persist: false,

  state: () => ({
    widget: false,
    preview: false,
    fatSearch: false,
    fatFilter: false,
    fatLocation: false,
    fatCalendar: false,
    fatLayout: 'card'
  })
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUiStore, import.meta.hot))
}
