import { acceptHMRUpdate, defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  persist: false,

  state: () => ({
    uts: null,
    pageviews: 0
  }),

  getters: {
    initialLoad() {
      return this.pageviews === 0
    },
    dev() {
      return useRoute().query?.dev ? true : false
    }
  },
  
  actions: {
    addPageview() {
      this.pageviews++
    },
    toggleDev() {
      useRouter().push({ query: { ...useRoute().query, dev: useRoute().query?.dev ? undefined : true }})
    },
    updateUts() {
      // const dayjs = useDayjs()
      // this.uts = dayjs().unix()
      this.uts = Math.floor(Date.now() / 1000)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
