<template>
  <div class="map" :key="restaurant.id">
    <div class="flex flex-col-reverse gap-0.5">
      <h2 class="text-sm" v-html="$t('restaurant.map.leading', restaurant)" />
      <p class="text-xl font-bold tracking-tight" v-html="$t('restaurant.map.heading')" />
    </div>
    <div class="aspect-video bg-sand text-coral">
      <AtomMap :lat="restaurant.location?.lat" :lon="restaurant.location?.lon" :zoom="16" />
    </div>
    <div class="absolute z-10 bottom-2 right-2 bg-white/40">
      <a class="button-outline text-xs" data-style="coral" :href="locationUrl" target="_blank">{{ $t('restaurant.map.button') }}</a>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  restaurant: {
    type: Object,
    default: () => {}
  }
})

// Google Maps URL
const coordinates = `${props.restaurant?.location?.lat},${props.restaurant?.location?.lon}`
const locationUrl = computed(() => `https://www.google.com/maps/place/${coordinates}`)
</script>

<style lang="scss" scoped>
.map {
  @apply grid gap-4 relative;
}
</style>