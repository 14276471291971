import { acceptHMRUpdate, defineStore } from 'pinia'

export const usePreviewStore = defineStore('preview', {
  persist: false,

  state: () => ({
    restaurantId: null,
    mealId: null,
  }),

  actions: {
    openRestaurant(restaurant) {
      const ui = useUiStore()

      this.restaurantId = restaurant.id
      this.mealId = null
      ui.preview = true
    },
    openMeal(meal, date) {
      const ui = useUiStore()
      const fat = useFatStore()

      // Check amount settings for meal
      const minAmount = meal.minAmount || 2
      const maxAmount = meal.maxAmount || 8
      const fatAmountValid = fat.amount >= minAmount && fat.amount <= maxAmount

      // Update FAT amount to be within min and max values
      fat.amount = fatAmountValid 
        ? fat.amount 
        : (minAmount || fat.amount)
      
      // Update FAT date according to provided date (use FAT date as fallback)
      fat.date = date || fat.date

      // Save Restaurant ID and Meal ID in preview store
      this.restaurantId = meal.restaurant.id
      this.mealId = meal.id
      ui.preview = true
    },
    close() {
      const route = useRoute()
      const router = useRouter()
      const ui = useUiStore()
      
      ui.preview = false

      router.push({ query: { ...route.query, preview: undefined, mealId: undefined }})
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePreviewStore, import.meta.hot))
}
