<template>
  <div class="amount">
    <div class="amount__header">
      <div class="grid gap-0.5">
        <div class="text-xl font-bold">{{ $t('fat.amount.heading') }}</div>
        <div class="text-sm">{{ $t('fat.amount.leading') }}</div>
      </div>
      <div class="flex items-center gap-2 text-sm line-through opacity-20">
        <AtomIcon name="circle-question" />
        <div>{{ $t('fat.amount.link') }}</div>
      </div>
    </div>
    <div class="amount__options">
      <div v-for="amount in 8" :key="`amount-${amount}`" class="amount__option">
        <div class="amount__pill" :class="{ 'selected': fat.amount === amount }" @click="onChange(amount)">
          {{ amount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const fat = useFatStore()

// Update amount
const onChange = (value) => {
  fat.setAmount(value)
  fat.pushQuery()
}
</script>

<style lang="scss" scoped>
.amount {
  &__header {
    @apply flex justify-between items-end mb-4;
  }

  &__options {
    @apply flex items-center justify-between;
  }

  &__option {
    @apply flex justify-center;
  }
  
  &__pill {
    @apply font-bold cursor-pointer;
    @apply w-8 h-8 rounded-full;
    @apply flex items-center justify-center transition-all;

    &.selected {
      @apply bg-coral text-white;
      @apply pointer-events-none cursor-default;
    }

    @screen md {
      &:hover {
        @apply bg-mist;
      }
    }
  }
}
</style>