<template>
  <div class="meal">
    <div class="meal__header">
      <div class="grid gap-0.5">
        <div class="text-xl font-bold">{{ $t('fat.meal.heading') }}</div>
        <div class="text-sm">{{ $t('fat.meal.leading') }}</div>
      </div>
      <div class="flex items-center gap-2 text-sm line-through opacity-20">
        <AtomIcon name="circle-question" />
        <div>{{ $t('fat.meal.link') }}</div>
      </div>
    </div>
    <div class="meal__options">
      <div v-for="mealType in fat.mealTypes" :key="mealType.slug" class="meal__option">
        <div class="meal__row" @click="onChange(mealType.slug)" :class="{ 'active': mealType.slug === fat.meal }">
          <div class="flex items-center gap-2">
            <div class="w-5 h-5 rounded-sm bg-white text-white">
              <div class="w-full h-full flex items-center justify-center rounded-sm border-sand transition-all" :class="{ 'bg-coral': mealType.slug === fat.meal, 'border-2': mealType.slug !== fat.meal }">
                <AtomIcon name="check" class="transition-all opacity-0" :class="{ 'opacity-100': mealType.slug === fat.meal }" />
              </div>
            </div>
            <div class="text-sm font-medium">
              {{ mealType.text }}
            </div>
          </div>
          <div class="text-sm opacity-60 flex items-center">
            <div class="w-10 text-right" v-html="mealType.mealStartTime" />
            <div class="w-4 text-center" v-html="`-`" />
            <div class="w-10 text-right" v-html="mealType.mealEndTime" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const fat = useFatStore()

// Update meal
const onChange = (mealSlug) => {
  fat.setMealType(mealSlug)
  fat.pushQuery()
}
</script>

<style lang="scss" scoped>
.meal {
  &__header {
    @apply flex justify-between items-end mb-2;
  }

  &__options {
    @apply w-full flex flex-col gap-0.5;
  }

  &__option {
    @apply -mx-2;
  }

  &__row {
    @apply flex items-center justify-between rounded-md;
    @apply transition-all cursor-pointer px-2 py-2;

    &.active {
      @apply bg-mist pointer-events-none cursor-default;
    }

    @screen md {
      &:hover {
        @apply bg-mist;
      }
    }
  }
}
</style>