<template>
  <div class="opening-hours" :key="restaurant.id">
    <div class="flex flex-col-reverse gap-0.5">
      <h2 class="text-sm" v-html="$t('restaurant.openingHours.leading', restaurant)" />
      <p class="text-xl font-bold tracking-tight" v-html="$t('restaurant.openingHours.heading')" />
    </div>
    <div v-if="!openingHours" class="text-sm opacity-60 p-4 bg-mist rounded-md">
      {{ $t('restaurant.openingHours.noData', restaurant)}}
    </div>
    <div class="opening-hours__list" v-if="openingHours">
      <div v-for="day in openingHours" :key="day.from" class="opening-hours__row">  
        <div v-if="(day.from === day.until)" class="text-sm">
          {{ $t(`shared.day.${day.from}`) }}
        </div>
        <div v-else class="text-sm">
          {{ $t(`shared.day.${day.from}`).slice(0, 3) }}-{{ $t(`shared.day.${day.until}`).slice(0, 3) }}
        </div>
        <div v-if="!day.open" class="text-sm" v-html="$t('restaurant.openingHours.closed') " />
        <div v-else class="text-sm">
          <div v-for="time in day.time" :key="time" v-html="time" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
  restaurant: {
    type: Object,
    default: () => {}
  }
})

//
// Create opening hours that are sorted and grouped
//
const useRestaurantOpeningHours = (openingHours) => {
  const hasOpeningHours = openingHours.filter(day => day.times.length)

  if (!hasOpeningHours.length) {
    return false
  }
  
  return openingHours
    .filter(item => item.day)
    .sort((a, b) => a.day - b.day)
    .reduce((arr, item) => {
      const lastItem = arr.slice(-1)[0];
      const lastItemIndex = arr?.length - 1;
      
      if (JSON.stringify(lastItem?.times) !== JSON.stringify(item?.times)) {
        arr.push({
          from: item.day,
          until: item.day,
          open: item.times.length,
          times: item.times,
          time: item.times.map(time => time.open + ' - ' + (time.close || t('restaurant.openingHours.late')))
        })
      } else {
        arr[lastItemIndex] = {
          ...lastItem,
          until: item.day,
        }
      }
      return arr
    }, [])
}

const openingHours = computed(() => useRestaurantOpeningHours(props.restaurant.openingHours))
</script>

<style lang="scss" scoped>
.opening-hours {
  @apply grid gap-4;

  &__info {
    @apply flex flex-col-reverse gap-1;
  }

  &__list {
    @apply border-b;
  }

  &__row {
    @apply border-t py-4;
    @apply flex justify-between;
  }
}
</style>